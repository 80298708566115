<template>
    <div class="resident-order-history">
        <ResidentHeader
            page-title="Commandes"
        />

        <div
            class="history-empty"
            v-if="loaded && months.length < 1"
        >
            Vous n'avez pas encore passé de commande.<br/>
            Si vous avez souscrit à un abonnement, votre première
            commande apparaîtra ici le 1er {{nextMonth}}.
        </div>

        <div class="month-histories">
            <div
                class="month-history"
                v-for="(item, itemIndex) in months"
                :key="itemIndex"
            >
                <div class="header-action">
                    <div class="month-title">{{ getMonthLabel(item.month) }} {{ item.year }}</div>
                    <HBtn
                      v-if="item.hasInvoices && $breakpoint.mdAndUp && $acl.not.check('isEhpadEmployee') && $acl.not.check('isEhpadManager')"
                      style-type="secondary"
                      icon="DownloadIcon"
                      class="download-all-btn"
                      data-cy="download-all-btn"
                      @click="(e) => {onDownloadAllBtnClick(item)}"
                    >Télécharger toutes les factures disponibles</HBtn>
                </div>
                <div class="month-orders">

                    <div
                        class="no-orders"
                        v-if="item.groups.length < 1"
                    >Pas de commandes ce mois-ci.</div>

                    <div v-else class="month-groups">
                        <div
                            class="group"
                            v-for="(group, groupIndex) in item.groups"
                            :key="groupIndex"
                        >
                            <div class="group-header">
                                <div class="header-left">
                                    <div class="group-id">N° : {{ group.id }}</div>
                                    <div class="group-date">Créée le {{ getDateLabel(group.date) }}</div>
                                </div>
                                <div class="header-right">
                                    <div class="group-type">{{ getPaymentTypeLabel(group.type) }}</div>
                                    <div class="group-amount">{{ group.amount }}€</div>
                                </div>
                            </div>
                            <HCard
                              type="free"
                              class="group-orders"
                            >
                                <div
                                    class="order"
                                    v-for="(order, orderIndex) in group.orders"
                                    :key="orderIndex"
                                >
                                    <div class="order-informations">
                                        <div
                                            class="order-status"
                                            :class="getStatusTextClass(order)"
                                        >● {{ getStatusText(order) }}</div>
                                        <div class="order-vendor">{{ order.sellerName }}</div>
                                    </div>
                                    <div class="order-price">{{ order.amount }}€</div>
                                    <div class="order-actions">
                                      <HBtn
                                        class="order-action-btn detail-btn"
                                        :data-cy="`detail-${order.id}-btn`"
                                        @click="(e) => {onOrderDetailBtnClick(order)}"
                                      >Détails</HBtn>
                                      <HBtn
                                        v-if="order.invoice.url && $acl.not.check('isEhpadEmployee') && $acl.not.check('isEhpadManager')"
                                        icon="DownloadIcon"
                                        style-type="secondary"
                                        class="order-action-btn invoice-btn"
                                        :data-cy="`download-${order.id}-btn`"
                                        @click="(e) => {onDownloadBtnClick(order)}"
                                      >Facture</HBtn>
                                    </div>
                                </div>
                            </HCard>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import jsZip from 'jszip'
import {
    saveAs
} from 'file-saver'

import Http from '@/http'
import Oauth from '@/oauth'

import appsettings from '@/appsettings'
import Routes from '@/constants/routes'
import Formats from '@/constants/formats'

import {
    getMonthLabel,
    getPaymentTypeLabel,
    getOrderStatusLabel,
    getOrderStatusTextClass
} from '@/modules/labels'
import {
    getTimestamp,
    getNextMonth,
    formatLocalDate
} from '@/modules/date'
import {
    getPaymentTypeIcon,
    getOrderStatusIcon
} from '@/modules/icons'

import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import {
  HBtn,
  HCard
} from '@happytal/bo-ui-library'

const vueAppEhpadApi = appsettings.VUE_APP_EHPAD_API

export default {
    props: [
        'resident_id'
    ],
    components: {
        ResidentHeader,
        HBtn,
        HCard
    },
    data () {
        return {
            loaded: false,
            selected: Routes.ResidentHistory,
            resident: {},
            orders: [],
            months: []
        }
    },
    computed: {
        nextMonth () {
            return getNextMonth()
        }
    },
    methods: {
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getCurrentResident () {
            return this.getResidentById(this.resident_id)
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        onOrderDetailBtnClick (order) {
            this.$router.push({
                name: Routes.ResidentOrder,
                params: {
                    resident_id: this.resident_id,
                    order_id: order.id
                }
            })
        },
        onOrderSummaryBtnClick (item) {
            this.$router.push({
                name: Routes.ResidentSummary,
                params: {
                    resident_id: this.resident_id,
                    month: item.month,
                    year: item.year
                }
            })
        },
        onDownloadAllBtnClick (item) {
            var orders = []
            item.groups.forEach((group) => {
                group.orders.forEach((order) => {
                    if (_.get(order, 'invoice.url', null)) {
                        orders.push(order)
                    }
                })
            })
            this.zipOrders(orders, this.getZipAllFileName(item))
        },
        onDownloadBtnClick (order) {
            this.zipOrders([order], this.getZipSingleFileName(order))
        },
        zipOrders (orders, zipFileName) {
            var zip = new jsZip()
            _.forEach(orders, (order, index) => {
                const fileName = _.get(order, 'invoice.fileName', '')
                const url = _.get(order, 'invoice.url', '')
                zip.file(`${index}-${fileName}`, this.urlToPromise(url))
            })
            zip.generateAsync({
                type: 'blob'
            })
            .then((blob) => {
                saveAs(blob, zipFileName)
            },
            (err) => {
                this.showErrorToast(err)
            })
        },
        urlToPromise (url) {
            return new Promise((resolve, reject) => {
                Http.apis.ehpad.request({
                        responseType: 'arraybuffer',
                        url,
                        method: 'get'
                    })
                    .then((response) => {
                        const data = response.data || {}
                        resolve(data)
                    }
                )
                .catch((error) => {
                    reject(error)
                })
            })
        },
        getZipResidentName () {
            const resident = this.getCurrentResident()
            return `${resident.firstName}_${resident.lastName}`
        },
        getZipDateLabel (date) {
            const year = date.year()
            const monthName = getMonthLabel(date.month())
            return `${monthName}_${year}`
        },
        getZipSingleFileName (order) {
            const date = moment(order.date)
            const dateLabel = this.getZipDateLabel(date)
            const residentName = this.getZipResidentName()
            const name = `${order.id}_${dateLabel}_${residentName}.zip`
            return name.replace(/\s/g, '-')
        },
        getZipAllFileName (item) {
            const date = moment()
            date.month(item.month)
            date.year(item.year)
            const dateLabel = this.getZipDateLabel(date)
            const residentName = this.getZipResidentName()
            const name = `${residentName}_${dateLabel}.zip`
            return name.replace(/\s/g, '-')
        },
        getOrderStatusIcon (order) {
            return getOrderStatusIcon(order)
        },
        getStatusText (order) {
            return getOrderStatusLabel(order)
        },
        getStatusTextClass (order) {
            return getOrderStatusTextClass(order)
        },
        getMonthLabel (month) {
            return getMonthLabel(month)
        },
        getPaymentTypeIcon (type) {
            return getPaymentTypeIcon(type)
        },
        getPaymentTypeLabel (type) {
            return getPaymentTypeLabel(type)
        },
        getDateLabel (date) {
            return formatLocalDate(date, Formats.OrderDate)
        },
        getFirstInvoice (salesDocuments) {
            return _.find(salesDocuments, (salesDocument) => {
                return salesDocument.type == 'Invoice'
            })
        },
        getMonthItemIndex (month, year) {
            return _.findIndex(this.months, (item) => {
                return item.month == month && item.year == year
            })
        },
        getGroupItemIndex (groups, id) {
            return _.findIndex(groups, (item) => {
                return item.id == id
            })
        },
        getMonthTimestamp (month, year) {
            return getTimestamp(moment([year, month, 1]))
        },
        updateItems () {
            this.months = []
            this.orders.forEach((order) => {
                const paymentType = _.get(order, 'metadata.order_type', '')
                const orderDate = moment(order.createdOnUtc)
                const month = orderDate.month()
                const year = orderDate.year()
                var monthIndex = this.getMonthItemIndex(month, year)
                if (monthIndex < 0) {
                    this.months.push({
                        month,
                        year,
                        timestamp: this.getMonthTimestamp(month, year),
                        groups: [],
                        hasInvoices: false
                    })
                    monthIndex = this.months.length - 1
                }
                const groups = this.months[monthIndex].groups
                var groupIndex = this.getGroupItemIndex(groups, order.id)
                if (groupIndex < 0) {
                    const totalPriceTTC = _.get(order, 'totalPriceTTC', 0.0)
                    this.months[monthIndex].groups.push({
                        id: order.commercialId,
                        date: orderDate,
                        amount: totalPriceTTC.toFixed(2),
                        type: paymentType,
                        orders: []
                    })
                    groupIndex = this.months[monthIndex].groups.length - 1
                }
                var hasInvoices = false
                _.forEach(order.orderLogisticProducts, (logisticProduct) => {
                    const salesDocuments = _.get(logisticProduct, 'salesDocuments', [])
                    const invoice = this.getFirstInvoice(salesDocuments)
                    const fileId = _.get(invoice, 'fileId', null)
                    const fileName = _.get(invoice, 'fileName', '')
                    if (!hasInvoices && fileName) {
                        hasInvoices = true
                    }
                    const invoiceUrl = fileId ? `${vueAppEhpadApi}/files/${fileId}?download=true` : null
                    const orderLines = _.get(logisticProduct, 'orderContent.orderLines', [])
                    /*amount += _.sumBy(orderLines, (orderLine) => {
                        var taxes = orderLine.totalPrice
                        taxes += _.sumBy(orderLine.shippingTaxes, (tax) => {
                            return tax.amount
                        })
                        taxes += _.sumBy(orderLine.taxes, (tax) => {
                            return tax.amount
                        })
                        return taxes
                    })*/
                    const sellerName = _.get(logisticProduct, 'orderContent.shopName', '')
                    const status = _.get(logisticProduct, 'orderContent.orderState', '')
                    const date = _.get(logisticProduct, 'orderContent.createdDate', '')
                    const totalPriceTTC = _.get(logisticProduct, 'orderContent.totalPriceTTC', 0.0)
                    this.months[monthIndex].groups[groupIndex].orders.push({
                        id: logisticProduct.id,
                        type: 'product',
                        date,
                        sellerName,
                        orderId: logisticProduct.id,
                        status,
                        paymentType,
                        amount: totalPriceTTC.toFixed(2),
                        invoice: {
                            fileName,
                            url: invoiceUrl
                        }
                    })
                })
                _.forEach(order.orderLogisticServices, (logisticService) => {
                    const salesDocuments = _.get(logisticService, 'salesDocuments', [])
                    const invoice = this.getFirstInvoice(salesDocuments)
                    const fileId = _.get(invoice, 'fileId', null)
                    const fileName = _.get(invoice, 'fileName', '')
                    if (!hasInvoices && fileName) {
                        hasInvoices = true
                    }
                    const invoiceUrl = fileId ? `${vueAppEhpadApi}/files/${fileId}?download=true` : null
                    const sellerName = _.get(logisticService, 'orderContent.shop.name', '')
                    const status = _.get(logisticService, 'orderContent.state', '')
                    const date = _.get(logisticService, 'orderContent.dateCreated', '')
                    const logisticAmount = _.get(logisticService, 'orderContent.totalPriceTTC', 0)
                    this.months[monthIndex].groups[groupIndex].orders.push({
                        id: logisticService.id,
                        type: 'service',
                        date,
                        sellerName,
                        orderId: logisticService.id,
                        status,
                        paymentType,
                        amount: logisticAmount.toFixed(2),
                        invoice: {
                            fileName,
                            url: invoiceUrl
                        }
                    })
                })
                if (!this.months[monthIndex].hasInvoices && hasInvoices) {
                    this.months[monthIndex].hasInvoices = true
                }
            })
            this.months = _.orderBy(this.months, ['timestamp'], ['desc'])
            this.months.forEach((item, monthIndex) => {
                this.months[monthIndex].groups = _.sortBy(this.months[monthIndex].groups, (group) => {
                    return -getTimestamp(group.date)
                })
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    async mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        })
                        .then(() => {

                            callback(null)
                        })
                        return
                    }
                    callback(null)
                },
                (callback) => {
                    this.resident = this.getCurrentResident()
                    this.$store.dispatch('dataOrders/getOrders', {
                        residentId: this.resident_id
                    })
                    .then((apiOrders) => {
                        this.orders = apiOrders
                        callback(null)
                    })
                }
            ],
            (err, results) => {
                Oauth.checkUserRight(this.$store.getters['dataResidents/getResidentById'](this.resident_id), this.$router)
                this.updateItems()
                this.loaded = true
            }
        )
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.resident-order-history {

    .history-empty {
        margin: 30px 0px 36px 0px;
        padding: 20px 18px 20px 18px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.170509);
        border-radius: 8px 8px 8px 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        color: #2C2C2C;
    }

    .month-histories {
        margin: 20px 0px 0px 0px;

        .month-history {

            .header-action {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin: 0px 0px 18px 0px;

                .month-title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 14px;
                    color: #606060;
                }
                .download-all-btn {
                    margin: 0px 0px 0px 14px;
                }
            }
            .month-orders {

                .no-orders {

                }
                .month-groups {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 48%);
                    grid-auto-rows: auto;
                    grid-gap: 22px;
                    row-gap: 22px;
                    margin: 0px 0px 41px 0px;

                    @media (max-width: map-get($breakpoints, sm)) {
                        grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
                    }

                    .group {
                        display: flex;
                        flex-direction: column;

                        .group-header {
                            display: flex;
                            padding: 11px 15px 14px 15px;
                            border-radius: 5px 5px 0px 0px;
                            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
                            background: #F9F9F9;

                            .header-left {
                                flex-grow: 1;

                                .group-id {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 15px;
                                    color: black;
                                }
                                .group-date {
                                    margin: 13px 0px 0px 0px;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 10px;
                                }
                            }
                            .header-right {

                                .group-type {
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 12px;
                                    line-height: 15px;
                                    text-align: right;
                                }
                                .group-amount {
                                    margin: 8px 0px 0px 0px;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 28px;
                                    line-height: 20px;
                                    text-align: right;
                                    color: rgba(var(--vs-primary), 1);
                                }
                            }
                        }
                        .group-orders {

                            .order {
                                display: flex;
                                padding: 17px 14px 17px 10px;
                                border-bottom: 1px solid #DDDDDD;

                                .order-informations {
                                    flex-grow: 1;

                                    .order-status {
                                        text-transform: uppercase;
                                    }
                                    .status-label-ok {
                                        margin: 0px 0px 0px 7px;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: rgba(var(--vs-primary), 1);
                                    }
                                    .status-label-ko {
                                        margin: 0px 0px 0px 7px;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                        color: #E03A3E;
                                    }
                                    .order-vendor {
                                        margin: 19px 0px 0px 20px;
                                        font-style: normal;
                                        font-weight: normal;
                                        font-size: 14px;
                                        line-height: 15px;
                                        color: black;
                                    }
                                }
                                .order-price {
                                    align-self: center;
                                    margin: 0px 20px 0px 0px;
                                }
                                .order-actions {
                                    display: flex;
                                    flex-direction: column;

                                    .order-action-btn {
                                        width: 106px;
                                    }
                                    .detail-btn {

                                    }
                                    .invoice-btn {
                                        margin: 8px 0px 0px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
